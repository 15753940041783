<template>
    <div
        v-cloak
        :class="{
            'file-drop': true,
            'has-file': (value, previewImage),
            'has-title': hasTitle,
            'shortened': shortened,
            'centered': (hasPlaceholder || centeredText),
            'centered-title': centeredTitle,
            'darked': (darked && (value || previewImage)),
            'rounded': rounded,
            'file-drop-dropping': dropping,
            'file-drop-error': error
        }"
        @drop.prevent="addFile"
        @dragover.prevent
        @dragenter.prevent="dropping = true"
        @dragleave.prevent="dropping = false"
        @click="selectFile">
        <div class="drop-title">
            <slot name="title" />
        </div>
        <span v-if="dropping">
            Drop the file!
        </span>
        <div
            v-else-if="error"
            class="error-holder">
            <span class="error-text">{{ error }}</span>
            <div class="selected-right">
                <a
                    href="#"
                    @click.stop.prevent="selectFile">
                    select another file
                </a>
            </div>
        </div>
        <div
            v-else-if="value || previewImage"
            class="selected-file">
            <div class="selected-left">
                <div
                    v-if="previewImage"
                    class="selected-img">
                    <img
                        :src="previewImage">
                </div>
                <icon
                    v-if="icon"
                    :name="icon" />
                <span
                    v-if="value"
                    class="selected-name">{{ value.name }}</span>
            </div>
            <div class="selected-right">
                <a
                    href="#"
                    @click.stop.prevent="selectFile">
                    Upload new
                </a>
            </div>
        </div>
        <div
            v-else
            class="placeholder-wrapper">
            <div
                v-if="!!$slots.placeholder"
                class="upload-text">
                <slot name="placeholder" />
            </div>
            <div class="drag-click-upload">
                <icon
                    size="35"
                    name="cloud"
                    color="#909FA8" />
                <span class="drag-text">Drop file to upload or </span>
                <a @click.stop="selectFile">click to select</a>
            </div>
        </div>
        <input
            ref="field"
            type="file"
            :accept="mimes.join(',')"
            hidden
            @change="onFileSelected">
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import extensions from '@/helpers/extensions';
import Papa from 'papaparse';
import { TEN_MEGABYTES } from '@/helpers/globals';

export default {
    components: {
        Icon,
    },
    props: {
        header: {
            type: Boolean,
            default: true,
        },
        previewImage: {
            type: String,
            default: null,
        },
        value: {
            required: true,
            validator(value) {
                if (value) {
                    return value instanceof File;
                }
                return true;
            }
        },
        parseFile: {
            default: false,
            type: Boolean
        },
        types: {
            type: Array,
            default() {
                return [];
            }
        },
        centeredText: {
            type: Boolean,
            default: false
        },
        centeredTitle: {
            type: Boolean,
            default: false
        },
        shortened: {
            type: Boolean,
            default: false
        },
        darked: {
            type: Boolean,
            default: false
        },
        rounded: {
            type: Boolean,
            default: false
        },
        aspectRatio: {
            type: String,
            default: ''
        },
        fileLimit: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            files: [],
            dropping: false,
            error: null
        };
    },
    computed: {
        icon() {
            if (!(this.value instanceof File)) {
                return null;
            }

            switch (this.value.type) {
                case 'text/csv':
                    return 'file-csv';
                default:
                    return null;
            }
        },
        mimes() {
            return this.types.map(extension => {
                return extensions.getMimeFromExt('.'+extension);
            });
        },
        hasPlaceholder() {
            return !!this.$slots.placeholder;
        },
        hasTitle() {
            return !!this.$slots.title;
        }
    },
    watch: {
        value(value) {
            // Allows external reset
            if (value === null) {
                this.$refs.field.value = '';
            }
        }
    },
    methods: {
        addFile(e) {
            this.dropping = false;
            const droppedFiles = e.dataTransfer.files;
            if (!droppedFiles) {
                return;
            }
            this.handleFile(droppedFiles[0]);
        },
        selectFile() {
            this.$refs.field.click();
        },
        onFileSelected() {
            if (this.$refs.field.files.length) {
                this.handleFile(this.$refs.field.files[0]);
            }
        },
        getExtension(filename) {
            const parts = filename.toLowerCase().split('.');
            return parts[parts.length - 1];
        },
        handleFile(file) {
            this.error = null;
            const ext = this.getExtension(file.name);
            if (this.mimes.length && !this.types.includes(file.name.toLowerCase().split('.').pop())) {
                this.error = `File type ${ext} isn't supported`;
                return;
            }
            if (this.parseFile && (file.name.toLowerCase().split('.').pop() === 'csv')) {
                Papa.parse(file, {
                    header: this.header,
                    complete: this.onParseCompletion
                });
            }
            if (this.fileLimit && file.size > TEN_MEGABYTES) {
                this.error = 'The file exceeds the max upload size of 10mb.';
                return;
            }
            if (this.aspectRatio == '1:1') {
                const img = new Image();
                img.src = URL.createObjectURL(file);
                img.onload = () => {
                    if (img.width != img.height) {
                        this.error = 'Only 1:1 aspect ratio allowed';
                    } else {
                        this.$emit('input', file);
                        this.$emit('file-handled', file);
                    }
                };
            } else {
                this.$emit('input', file);
                this.$emit('file-handled', file);
            }
        },
        onParseCompletion(results) {
            this.$emit('file-parsed', results);
        },
        /*removeFile() {
            this.$refs.field.value = '';
            this.$emit('input', null);
            this.$emit('file-parsed', {});
            this.$emit('file-removed', {});
        }*/
    }
};
</script>

<style lang="scss" scoped>

.file-drop {
    display: block;
    padding: 20px;
    min-height: 110px;
    color: $loblolly;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23A9A9A9' stroke-width='5' stroke-dasharray='5%2c 15' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease-in-out;
    position: relative;
    &.file-drop-error {
        border-color: $carnation;
        text-align: center;
    }
    &:hover {
        background-color: darken($bg-light-container, 10%);
        cursor: pointer;
    }
    &.file-drop-dropping {
         background-color: darken($bg-light-container, 20%);
         color: $white;
    }
    .selected-file {
        color: $gray-dark;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 75%;
        max-width: 400px;
    }
    .selected-left{
        display: flex;
        align-items: center;
    }
    .selected-img{
        margin-right: 15px;
        img{
            max-width: 80px;
            max-height: 80px;
        }
    }
    svg {
        vertical-align: middle;
    }
    .upload-text {
      padding: 0 28px 0 23px;
    }
    .placeholder-wrapper{
        display: flex;
        align-items: center;
    }
    &.has-title{
        margin-top: 35px;
    }
    &.darked{
        background: #909FA8;
    }
    .drop-title{
        position: absolute;
        top: -23px;
        left: 10px;
        font-size: 12px;
    }
    &.shortened{
        margin-bottom: 15px;
        .selected-right{
            position: absolute;
            bottom: -24px;
            left: 50%;
            transform: translateX(-50%);
        }
        .selected-name{
            display: none;
        }
        .selected-file{
            justify-content: center;
        }
        .selected-img{
            margin-right: 0;
        }
    }
    &.rounded{
        .selected-img{
            width: 80px;
            height: 80px;
            img{
                width: inherit;
                height: inherit;
                object-fit: cover;
                border-radius: 50%;
            }
        }
    }
    .selected-right{
        white-space: nowrap;
    }
    .error-holder{
        text-align: center;
        padding: 23px;
    }
    .error-text{
        color: red;
    }
    .error-holder{
        .selected-right{
            position: relative;
            bottom: auto;
            left: auto;
            transform: none;
            margin-top: 5px;
        }
    }
    &.centered-title{
        .drop-title{
            text-align: center;
            right: 0;
            font-size: 14px;
            top: -30px;
            left:0;
        }
    }
    &.centered{
        padding: 10px 20px;
        .drag-click-upload{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .drag-text{
            margin-left:0;
        }
    }
    &.has-file{
        border: 2px solid #A9A9A9;
        background-image: none;
        min-height: 133px;
    }
    .drag-click-upload {
        min-width: 200px;
        text-align: center;
        margin: 5px;
        padding: 13px;
        color: #3B3B3B;
    }
    .drag-text{
        margin-left: 20px;
    }
}
</style>