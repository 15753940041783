var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
        'file-drop': true,
        'has-file': (_vm.value, _vm.previewImage),
        'has-title': _vm.hasTitle,
        'shortened': _vm.shortened,
        'centered': (_vm.hasPlaceholder || _vm.centeredText),
        'centered-title': _vm.centeredTitle,
        'darked': (_vm.darked && (_vm.value || _vm.previewImage)),
        'rounded': _vm.rounded,
        'file-drop-dropping': _vm.dropping,
        'file-drop-error': _vm.error
    },on:{"drop":function($event){$event.preventDefault();return _vm.addFile.apply(null, arguments)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();_vm.dropping = true},"dragleave":function($event){$event.preventDefault();_vm.dropping = false},"click":_vm.selectFile}},[_c('div',{staticClass:"drop-title"},[_vm._t("title")],2),(_vm.dropping)?_c('span',[_vm._v(" Drop the file! ")]):(_vm.error)?_c('div',{staticClass:"error-holder"},[_c('span',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.error))]),_c('div',{staticClass:"selected-right"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.selectFile.apply(null, arguments)}}},[_vm._v(" select another file ")])])]):(_vm.value || _vm.previewImage)?_c('div',{staticClass:"selected-file"},[_c('div',{staticClass:"selected-left"},[(_vm.previewImage)?_c('div',{staticClass:"selected-img"},[_c('img',{attrs:{"src":_vm.previewImage}})]):_vm._e(),(_vm.icon)?_c('icon',{attrs:{"name":_vm.icon}}):_vm._e(),(_vm.value)?_c('span',{staticClass:"selected-name"},[_vm._v(_vm._s(_vm.value.name))]):_vm._e()],1),_c('div',{staticClass:"selected-right"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.selectFile.apply(null, arguments)}}},[_vm._v(" Upload new ")])])]):_c('div',{staticClass:"placeholder-wrapper"},[(!!_vm.$slots.placeholder)?_c('div',{staticClass:"upload-text"},[_vm._t("placeholder")],2):_vm._e(),_c('div',{staticClass:"drag-click-upload"},[_c('icon',{attrs:{"size":"35","name":"cloud","color":"#909FA8"}}),_c('span',{staticClass:"drag-text"},[_vm._v("Drop file to upload or ")]),_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.selectFile.apply(null, arguments)}}},[_vm._v("click to select")])],1)]),_c('input',{ref:"field",attrs:{"type":"file","accept":_vm.mimes.join(','),"hidden":""},on:{"change":_vm.onFileSelected}})])
}
var staticRenderFns = []

export { render, staticRenderFns }