import allExtensions from '../data/extensions.json';

const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
};


export default {
    list() {
        return allExtensions;
    },
    getMimeFromExt(extension) {
        return allExtensions[extension];
    },
    getExtFromMime(mime) {
        return getKeyByValue(allExtensions, mime);
    }
};
